import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import { useGetUpcomingGames } from 'hooks';

import GameDate from 'components/GameDate';
import {
  Loading,
  GameInfoButton,
  ErrorMessage,
  Teams,
  TvChannel,
  GameResult,
} from 'components';

import 'swiper/swiper-bundle.css';
import { StyledTitle } from 'theme/titles.js';
import {
  StyledSection,
  LeftSideBar,
  RightSideBar,
  SlideContent,
} from './style';

export const DashboardUpcomingGames = ({ setEventLeague }) => {
  const [searchParams] = useSearchParams();
  const leagueId = searchParams.get('leagueId');
  const league = searchParams.get('league');

  const {
    status: gamesStatus,
    data: games,
    isLoading: gamesIsLoading,
  } = useGetUpcomingGames(leagueId);

  //** FIND event_id FUNCTION TO SET IN URL PARAM **//
  const slideChanged = useCallback(
    (index) => {
      if (!games?.length) return;
      const eventLeagueName = games[index]?.event_league_name;
      if (eventLeagueName) {
        setEventLeague(eventLeagueName);
      }
    },
    [games, setEventLeague]
  );

  return (
    <>
      {gamesIsLoading ? <Loading /> : null}

      {!gamesIsLoading && gamesStatus === 'error' ? <ErrorMessage /> : null}

      {!gamesIsLoading && !games?.data?.length && league ? (
        <StyledTitle>{`There are no upcoming games for ${league} league`}</StyledTitle>
      ) : null}

      {!gamesIsLoading && games?.data?.length ? (
        <>
          <LeftSideBar>
            <div className="swiper-button-prev-unique" />
          </LeftSideBar>
          <StyledSection>
            <Swiper
              grabCursor={true}
              id="main-slider"
              initialSlide={0}
              modules={[Navigation, Mousewheel]}
              mousewheel={true}
              navigation={{
                prevEl: '.swiper-button-prev-unique',
                nextEl: '.swiper-button-next-unique',
              }}
              onSlideChange={(swiper) => {
                slideChanged(swiper.activeIndex);
              }}
              slidesPerView={1}
              tag="section"
              wrapperTag="ul"
            >
              {games?.data.map((game, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={`${game.event_id}-${index}`} tag="li">
                  <SlideContent>
                    <Teams
                      eventType={game?.event_type}
                      location={game.event_location}
                      opponent={game.team_b_event_text}
                      team={game.team_a_event_text}
                    />
                    <GameInfoButton
                      city={game.location_city}
                      date={game.event_start}
                      eventLeagueName={game.event_league_name}
                      locationId={game.event_location_id}
                      opponent={game.event_text}
                      region={game.stadium_country}
                      stadium={game.stadium_name}
                      stadiumLocation={{
                        lat: game?.stadium_lat,
                        lng: game?.stadium_lng,
                      }}
                      team={game.team_a_event_text}
                      timeZone={game.stadium_time_zone}
                      zipCode={game.stadium_zip_code}
                    />
                    <GameDate
                      eventState={game.event_state}
                      eventTime={game.event_start}
                    />
                    <TvChannel channel={game.event_tv_channel} />
                    <GameResult
                      date={game.event_start}
                      eventNotesHeadline={game.event_notes_headline}
                      result={game.event_outcome}
                    />
                  </SlideContent>
                </SwiperSlide>
              ))}
            </Swiper>
          </StyledSection>
          <RightSideBar>
            <div className="swiper-button-next-unique" />
          </RightSideBar>
        </>
      ) : null}
    </>
  );
};
