import axios from 'axios';
import { isGameCurrent, removePastGames } from '../helpers';

const APIKey = process.env.REACT_APP_WEATHER_API_KEY;
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

// Setup base url based on url, ie environment
const PROD_API_BASE_URL = process.env.REACT_APP_PROD_API_BASE_URL;
const DEV_API_URL = process.env.REACT_APP_DEV_API_BASE_URL;
const LOCAL_API_URL = 'http://127.0.0.1:8000';

let base_api_url = PROD_API_BASE_URL;

if (['downkount-dev.web.app'].includes(window.location.host)) {
  base_api_url = DEV_API_URL;
} else if (['localhost:3000'].includes(window.location.host)) {
  base_api_url = LOCAL_API_URL;
}

export const api = {
  leagues: {
    getLeagues: () => axios.get(`${base_api_url}/api/league`),
    getCollegeLeagues: () => axios.get(`${base_api_url}/api/getCollegeLeagues`),
  },
  teams: {
    getTeams: (id) => axios.get(`${base_api_url}/api/get_teams/${id}`),
  },
  games: {
    getSchedule: (id) => axios.get(`${base_api_url}/api/get_schedule/${id}`),
    getUpcomingGames: ({ leagueId }) =>
      axios.post(`${base_api_url}/api/getUpcomingEvents`, {
        league_id: leagueId,
      }),
    getCollegeUpcomingEvents: ({ leagueId, teamId }) =>
      axios.post(`${base_api_url}/api/getCollegeUpcomingEvents`, {
        one_league_id: leagueId,
        one_team_id: teamId,
      }),
    getCollegeSchedule: ({ leagueId, teamId }) =>
      axios.post(`${base_api_url}/api/getCollegeSchedule`, {
        one_league_id: leagueId,
        one_team_id: teamId,
      }),
    getTodaysEvents: () => axios.post(`${base_api_url}/api/getTodaysEvents`),
    getStadiumByLocationId: (locationId) =>
      axios.get(`${base_api_url}/api/getStadiumByLocationID/${locationId}`),
  },
  config: {
    getConfig: (site_url) =>
      axios.post(`${base_api_url}/api/siteInfoByUrl`, site_url),
  },
  user: {
    loginUser: (data) => {
      const bodyFormData = new FormData();
      bodyFormData.append('email', data.email);
      bodyFormData.append('password', data.password);

      return axios.post(`${base_api_url}/api/login`, bodyFormData);
    },

    loginWithUser: (data) =>
      axios.post(`${base_api_url}/api/registerGoogle`, data),

    loginWithFacebookUser: (data) =>
      axios.post(`${base_api_url}/api/registerFacebook`, data),

    registerUser: (data) => {
      const { email, password } = data;
      const bodyFormData = new FormData();
      bodyFormData.append('email', email);
      bodyFormData.append('password', password);

      return axios.post(`${base_api_url}/api/register`, bodyFormData);
    },

    resetPassword: (data) => {
      const { email } = data;
      const bodyFormData = new FormData();
      bodyFormData.append('email', email);

      return axios.post(`${base_api_url}/api/password/email`, bodyFormData);
    },

    setNewPassword: (data) => {
      const { email, password, confirmPassword, token } = data;
      const bodyFormData = new FormData();
      bodyFormData.append('email', email);
      bodyFormData.append('password', password);
      bodyFormData.append('password_confirmation', confirmPassword);
      bodyFormData.append('token', token);

      return axios.post(`${base_api_url}/api/password/reset`, bodyFormData);
    },
  },
  favorites: {
    getFavoritesTeams: (id) =>
      axios.get(`${base_api_url}/api/favouriteTeam/${id}`),
    addToFavoriteTeams: (data) => {
      const { userId, teamId, teamLabel } = data;
      const bodyFormData = new FormData();
      bodyFormData.append('userId', userId);
      bodyFormData.append('teamId', teamId);
      bodyFormData.append('teamLabel', teamLabel);

      return axios.post(`${base_api_url}/api/favourite`, bodyFormData);
    },
    deleteFavoriteTeam: (data) =>
      axios.get(
        `${base_api_url}/api/favourite_delete/${data.teamId}/${data.userId}`
      ),
  },
  news: {
    getAllNewsByTeam: (id) => axios.get(`${base_api_url}/api/news/${id}`),
  },
  customEvents: {
    getAllEvents: () => axios.get(`${base_api_url}/api/custom_event`),
    getSelectedEvent: (id) =>
      axios.get(`${base_api_url}/api/custom_event/${id}`),
  },
};

export default api;

/** API calls when url parsing after sharing */

export async function getTeamsSchedule(id) {
  try {
    const response = await axios.get(`${base_api_url}/api/get_schedule/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getTeamsList(id) {
  try {
    const response = await axios.get(`${base_api_url}/api/get_teams/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getLeaguesList() {
  try {
    const response = await axios.get(`${base_api_url}/api/league`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getWeather({ location }) {
  try {
    const response = await axios.get(
      `https://api.weatherapi.com/v1/forecast.json?key=${APIKey}&q=${location}&days=5&aqi=no&alerts=no`
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getGeoCoordinates({ stadium, region }) {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${stadium}&region=${
        region || 'us'
      }&key=${googleApiKey}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function saveFirebaseToken(token) {
  try {
    await axios.post(`${base_api_url}/api/push_notification`, token);
  } catch (error) {
    console.log(error);
  }
}

export async function getChampionshipGames(id) {
  try {
    const response = await axios.get(`${base_api_url}/api/get_teams/${id}`);
    const futureGames = removePastGames(response.data ?? []);
    const currentGame = isGameCurrent({
      games: futureGames,
      isChampionships: true,
    });

    return { data: futureGames, currentGame };
  } catch (error) {
    throw new Error();
  }
}
