/* eslint-disable react/destructuring-assignment */
import React from 'react';
import GoogleMapReact from 'google-map-react';

import { GeoLocationIcon } from 'assets/icons';
import { PointOnMapWrap, MarkerWrapper, MapWrapper } from './style';

/**
 * @fileoverview Google Map component for displaying location markers
 *
 * @known-issue findDOMNode Warning
 * This component uses google-map-react which internally uses findDOMNode.
 * This causes React StrictMode warnings in the console:
 * "Warning: findDOMNode is deprecated in StrictMode..."
 *
 * This is a known issue with the google-map-react library and not with our implementation.
 * The warning doesn't affect functionality and can be safely ignored until the library
 * updates its implementation to use modern React patterns.
 *
 * Related discussions:
 * - https://github.com/google-map-react/google-map-react/issues/914
 * - https://github.com/google-map-react/google-map-react/issues/924
 */

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const Marker = ({ lat, lng }) => (
  <PointOnMapWrap>
    <MarkerWrapper $lat={lat} $lng={lng}>
      <GeoLocationIcon />
    </MarkerWrapper>
  </PointOnMapWrap>
);

/**
 * Google Map component that displays a location marker
 * @param {Object} props - Component props
 * @param {Object} props.location - Location coordinates
 * @param {number} props.location.lat - Latitude
 * @param {number} props.location.lng - Longitude
 * @returns {React.ReactElement} Map component with marker
 */
const GoogleMap = ({ location }) => (
  <MapWrapper>
    {/* @ts-expect-error - GoogleMapReact uses findDOMNode internally */}
    <GoogleMapReact
      bootstrapURLKeys={{ key: googleApiKey }}
      defaultCenter={location}
      defaultZoom={15}
    >
      <Marker lat={location.lat} lng={location.lng} />
    </GoogleMapReact>
  </MapWrapper>
);

export default GoogleMap;
