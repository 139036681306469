import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { useGetCoordinates } from '../../../hooks/useGetCoordinates';
import { useStadiumInfo } from '../../../hooks/useStadiumInfo';
import { MapWithDirection } from './MapWithDirection';

export const GameInfoModal = ({
  city,
  locationId,
  onClose,
  region,
  show = false,
  stadium,
  stadiumLocation,
}) => {
  // Use a ref to track if we've shown the error toast
  const hasShownError = useRef(false);

  const { stadiumInfo, isLoading: isLoadingStadium } = useStadiumInfo({
    location_id: locationId,
    stadium,
    stadium_location: stadiumLocation,
  });

  const effectiveStadium = stadiumInfo?.data;
  const stadiumName =
    stadium ||
    effectiveStadium?.stadium_name ||
    effectiveStadium?.location_name;
  const locationCity = city || effectiveStadium?.location_city;

  const { data: geocodingResponse, isLoading: isLoadingGeo } =
    useGetCoordinates({
      stadium: stadiumName,
      city: locationCity,
      isStadiumCoordinates: !!(stadiumLocation?.lat && stadiumLocation?.lng),
      region,
      show,
    });

  // Parse coordinates from Google Geocoding response
  const coordinates = geocodingResponse?.results?.[0]?.geometry?.location;

  const stadiumCoords = {
    lat:
      stadiumLocation?.lat || effectiveStadium?.stadium_lat || coordinates?.lat,
    lng:
      stadiumLocation?.lng || effectiveStadium?.stadium_lng || coordinates?.lng,
  };

  // Get the effective stadium name and location info
  const displayName = stadiumName
    ? `${stadiumName} in ${locationCity}`
    : locationCity;

  useEffect(() => {
    // Only show error if:
    // 1. Modal is visible
    // 2. All data fetching is complete
    // 3. We have no coordinates AND no stadium info
    // 4. Haven't shown error yet
    const isLoading = isLoadingStadium || isLoadingGeo;
    const hasNoCoords = !stadiumCoords.lat && !stadiumCoords.lng;
    const hasNoStadiumInfo = !effectiveStadium?.stadium_name && !locationCity;

    if (
      show &&
      !isLoading &&
      hasNoCoords &&
      hasNoStadiumInfo &&
      !hasShownError.current
    ) {
      hasShownError.current = true;
      toast.error(
        'Location info for selected game is currently unavailable. Please try again later.'
      );
    }

    // Reset the error flag when the modal closes
    if (!show) {
      hasShownError.current = false;
    }
  }, [
    stadiumCoords.lat,
    stadiumCoords.lng,
    show,
    isLoadingStadium,
    isLoadingGeo,
    effectiveStadium?.stadium_name,
    locationCity,
  ]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative h-[80vh] w-[80vw] rounded-lg bg-black p-4">
        <button
          className="absolute right-2 top-2 text-2xl text-white"
          onClick={onClose}
          type="button"
        >
          X
        </button>
        <div className="h-full w-full">
          {isLoadingStadium || isLoadingGeo ? (
            <div className="flex h-full items-center justify-center text-[#FF1F8C]">
              Loading stadium location...
            </div>
          ) : stadiumCoords.lat && stadiumCoords.lng ? (
            <MapWithDirection
              destinationCoords={stadiumCoords}
              onClose={onClose}
              show={show}
              stadium={displayName}
            />
          ) : effectiveStadium?.stadium_name || locationCity ? (
            <div className="flex h-full flex-col items-center justify-center text-white">
              <h2 className="text-2xl font-bold mb-4">
                {displayName || 'Location Unavailable'}
              </h2>
              {effectiveStadium?.stadium_address ? (
                <p className="text-lg mb-2">
                  {effectiveStadium.stadium_address || 'Address Unavailable'}
                </p>
              ) : null}
              {locationCity ? (
                <p className="text-lg">{locationCity || 'City Unavailable'}</p>
              ) : null}
              <p className="text-[#FF1F8C] mt-4">Map directions unavailable</p>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center text-[#FF1F8C]">
              Stadium location is currently unavailable.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
