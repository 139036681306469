import styled from 'styled-components';

export const MapWrapper = styled.div`
  height: 370px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

export const PointOnMapWrap = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -100%);
  transition: transform 0.3s ease-in-out;
`;

export const MarkerWrapper = styled.div`
  position: relative;
  transition: transform 0.3s ease-in-out;
  ${(props) => props.$lat && ''} /* Use transient prop */
  ${(props) => props.$lng && ''} /* Use transient prop */
`;
