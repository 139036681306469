import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AdditionalInfoIcon } from '../../../assets/icons';
import { CloseRoundButton } from '../../Button/style';

const StyledAdditionalInfo = styled(CloseRoundButton)`
  position: relative;
  margin-left: 8px;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
`;

const AdditionalInfo = ({ onClick }) => (
  <StyledAdditionalInfo onClick={onClick}>
    <AdditionalInfoIcon />
  </StyledAdditionalInfo>
);

AdditionalInfo.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AdditionalInfo;
