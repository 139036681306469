import React from 'react';
import ReactDOM from 'react-dom';
// import moment from 'moment';
import { getAnalytics, logEvent } from 'firebase/analytics';

import {
  // AdditionalInfoIcon,
  // AiSearchIcon,
  InfoIcon,
  MapIcon,
  TicketIcon,
  // TransportIcon,
  // WeatherIcon,
} from 'assets/icons';
// import { Tooltip } from 'components';
import AdditionalInfo from './components/AdditionalInfo';
import { GameInfoModal } from './components/GameInfoModal';
import GameWebSearch from './components/GameWebSearch';
import WeatherInfoModal from './components/WeatherInfoModal';
import {
  CenterSpacer,
  ExpandedMenuContainer,
  GameInfoContainer,
  IconContainer,
  InfoIconContainer,
  StyledLink,
} from './style';

const SEARCH_TICKET_URL = process.env.REACT_APP_SEARCH_TICKET_URL;
// const SEARCH_FLIGHT_URL = process.env.REACT_APP_EXPEDIA_SEARCH_FLIGHT_URL;

export const GameInfoButton = ({
  city,
  date,
  eventLeagueName,
  locationId,
  opponent,
  region,
  stadium,
  stadiumLocation,
  team,
  timeZone,
  zipCode,
}) => {
  // Log google analytics events
  const analytics = getAnalytics();
  const ref = React.useRef(null);

  const [expandedMenu, setExpandedMenu] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showWeatherModal, setShowWeatherModal] = React.useState(false);
  const [showWebSearchModal, setShowWebSearchModal] = React.useState(false);
  const [showAdditionalInfoModal, setShowAdditionalInfoModal] =
    React.useState(false);

  const modalHandler = React.useCallback(() => {
    logEvent(analytics, 'select_content', {
      content_type: 'Show game info',
      content_id: `${team} - ${opponent}`,
    });

    setExpandedMenu((prevState) => !prevState);
  }, [analytics, opponent, team]);

  const clickMapHandler = React.useCallback(() => {
    setShowModal((prevState) => !prevState);
  }, []);

  const clickWeatherHandler = React.useCallback(() => {
    setShowWeatherModal((prevState) => !prevState);
  }, []);

  const clickWebSearchHandler = React.useCallback(() => {
    setShowWebSearchModal((prevState) => !prevState);
  }, []);

  const clickAdditionalInfoHandler = React.useCallback(() => {
    setShowAdditionalInfoModal((prevState) => !prevState);
  }, []);

  const handleClickOutside = (e) => {
    if (
      expandedMenu &&
      ref.current &&
      !ref.current?.contains(e.target) &&
      !showModal &&
      !showWeatherModal &&
      !showWebSearchModal &&
      !showAdditionalInfoModal
    ) {
      setExpandedMenu(false);
    }
  };

  // const isWeatherDisabled = moment(date).isBefore(moment());

  React.useEffect(() => {
    if (expandedMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <GameInfoContainer>
      <InfoIconContainer onClick={modalHandler}>
        <InfoIcon />
      </InfoIconContainer>
      <ExpandedMenuContainer $isOpen={expandedMenu} ref={ref}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <StyledLink target="_blank" to={SEARCH_TICKET_URL}>
            <TicketIcon />
          </StyledLink>

          {/* <IconContainer onClick={clickAdditionalInfoHandler}>
            <AdditionalInfoIcon />
          </IconContainer> */}

          {/* <IconContainer onClick={clickWebSearchHandler}>
            <AiSearchIcon />
          </IconContainer> */}
        </div>

        <CenterSpacer />

        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <IconContainer onClick={clickMapHandler}>
            <MapIcon />
          </IconContainer>

          {/* <Tooltip
            isActive={isWeatherDisabled}
            text="Weather forecast is not available for past games"
          >
            <IconContainer
              $isDisabled={isWeatherDisabled}
              onClick={clickWeatherHandler}
            >
              <WeatherIcon />
            </IconContainer>
          </Tooltip> */}

          {/* <StyledLink target="_blank" to={SEARCH_FLIGHT_URL}>
            <TransportIcon />
          </StyledLink> */}
        </div>
      </ExpandedMenuContainer>

      {showModal
        ? ReactDOM.createPortal(
            <GameInfoModal
              city={city}
              locationId={locationId}
              onClose={clickMapHandler}
              opponent={opponent}
              region={region ? region.toLowerCase() : ''}
              show={showModal}
              stadium={stadium}
              stadiumLocation={stadiumLocation}
              team={team}
              timeZone={timeZone}
              zipCode={zipCode}
            />,
            document.body
          )
        : null}

      {showWeatherModal
        ? ReactDOM.createPortal(
            <WeatherInfoModal
              date={date}
              onClose={clickWeatherHandler}
              show={showWeatherModal}
              zipCode={zipCode}
            />,
            document.body
          )
        : null}

      {showWebSearchModal
        ? ReactDOM.createPortal(
            <GameWebSearch
              eventLeagueName={eventLeagueName}
              onClose={clickWebSearchHandler}
              opponent={opponent}
              show={showWebSearchModal}
              team={team}
            />,
            document.body
          )
        : null}

      {showAdditionalInfoModal
        ? ReactDOM.createPortal(
            <AdditionalInfo
              onClose={clickAdditionalInfoHandler}
              show={showAdditionalInfoModal}
              team={team}
            />,
            document.body
          )
        : null}
    </GameInfoContainer>
  );
};
