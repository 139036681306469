import React from 'react';

export const AdditionalInfoIcon = () => (
  <svg
    fill="none"
    height="24"
    stroke="#FFFFFF"
    strokeWidth="1"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" />
    <path d="M12 7v2M12 11v6" strokeLinecap="round" />
  </svg>
);
