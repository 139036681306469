import types from 'redux/types';

const DEFAULT_ERROR_MESSAGE =
  'It seems we cannot load list of your favorite teams right now. We are working on it.';

export const favoriteTeamsError = (errorMessage = DEFAULT_ERROR_MESSAGE) => ({
  type: types.GET_FAVORITE_TEAMS_ERROR,
  payload: errorMessage,
});

export const favoriteTeamsSuccess = (data) => ({
  type: types.GET_FAVORITE_TEAMS_SUCCESS,
  payload: data,
});
