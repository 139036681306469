import React, { useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { validateEmail } from 'helpers';
import api from 'api/api.js';
import { getAnalytics, logEvent } from 'firebase/analytics';

import CustomInput from 'components/CustomInput';
import Button from 'components/Button';

const SetNewPasswordForm = () => {
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState(false);

  const saveNewPassword = useMutation((data) => api.user.setNewPassword(data), {
    onError: () => {
      toast.error(
        'An error occurred while saving new password. Please make sure that you entered correct registration email or try again later.'
      );

      logEvent(analytics, 'select_content', {
        content_type: 'error',
        content_id: `An error occurs during saving new password for user with email ${email}. Site url: ${window.location.href}`,
      });
    },
    onSuccess: () => {
      toast.success('New password successfully saved. You can sign in now');
      setEmail('');
      setPassword('');
      setConfirmPassword('');

      setTimeout(() => {
        navigate('');
      }, 3100);
    },
  });

  const emailErrorHandler = useCallback(() => {
    if (email !== '' && !validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  }, [email]);

  const emailInputHandler = useCallback(
    (inputValue) => {
      setEmail(inputValue);
      if (inputValue !== '' && !validateEmail(email)) {
        setEmailError(true);
        return;
      }
      setEmailError(false);
    },
    [email]
  );

  const setNewPasswordHandler = useCallback(() => {
    if (!emailError && email && password && token) {
      saveNewPassword.mutate({ email, password, confirmPassword, token });
    }
  }, [confirmPassword, email, emailError, password, saveNewPassword, token]);

  const isPasswordMatch = password === confirmPassword;

  const isButtonDisabled =
    !email || !password || !confirmPassword || !isPasswordMatch || emailError;

  return (
    <>
      <CustomInput
        isError={emailError}
        onBlur={emailErrorHandler}
        onChange={emailInputHandler}
        placeholder="Your registration email"
        type="email"
        value={email}
      />
      <CustomInput
        isPassword
        onChange={setPassword}
        placeholder="New password"
        type="password"
        value={password}
      />
      <CustomInput
        isError={password && confirmPassword ? !isPasswordMatch : null}
        isPassword
        onChange={setConfirmPassword}
        placeholder="Confirm new password"
        type="password"
        value={confirmPassword}
      />
      <Button
        disabled={isButtonDisabled || saveNewPassword.isLoading}
        onClick={setNewPasswordHandler}
        title="Change password"
      />
    </>
  );
};

export default SetNewPasswordForm;
