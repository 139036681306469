import React from 'react';
import Modal from 'components/Modal';
import { useGetWeather } from 'hooks';
import { SecondaryLoader } from 'components/Loading/SecondaryLoader';
import { WeatherGameInfo } from './WeatherGameInfo';

const WeatherInfoModal = ({ date, onClose, show, zipCode }) => {
  const { data, isLoading, status } = useGetWeather({ show, zipCode });

  return (
    <Modal onClose={onClose} show={show}>
      {isLoading ? (
        <SecondaryLoader />
      ) : (
        <WeatherGameInfo
          data={data}
          date={date}
          onClose={onClose}
          status={status}
        />
      )}
    </Modal>
  );
};

export default WeatherInfoModal;
