import { createGlobalStyle } from 'styled-components';
import { font } from 'theme';
import '../../assets/fonts/fonts.css';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${font.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input, textarea, button {
    font-family: ${font.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
