import React, { useState, useEffect, memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';

import GameDate from 'components/GameDate';
import {
  GameInfoButton,
  Teams,
  TvChannel,
  LeagueName,
  GameResult,
} from 'components';
import { CurrentGameButton } from './components/CurrentGameButton';

import 'swiper/swiper-bundle.css';
import {
  StyledSection,
  LeftSideBar,
  RightSideBar,
  SlideContent,
} from './style';

const DashboardOneTeam = ({
  games,
  currentGame,
  configuration,
  setEventLeague,
  eventLeague,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [progress, setProgress] = useState(games[currentGame]?.event_id);

  //** URL CHANGE ** //
  useEffect(() => {
    setSearchParams(
      (prevValue) => {
        prevValue.set('team', configuration.one_team_name);
        prevValue.set('teamId', configuration.one_team_id);
        prevValue.set('eventId', progress);
        return prevValue;
      },
      { replace: true }
    );
    // eslint-disable-next-line
  }, [progress]);

  //** FIND event_id FUNCTION */
  const slideChanged = (index) => {
    if (!games) return;
    const oneGame = games[index]?.event_id;
    if (oneGame) {
      setProgress(oneGame);
    }
    const eventLeagueName = games[index]?.event_league_name;
    if (eventLeagueName) {
      console.dir(eventLeagueName);
      setEventLeague(eventLeagueName);
    }
  };

  useEffect(() => {
    if (games?.length && eventLeague == '') {
      let event = games[0];
      if (event?.event_league_name) {
        setEventLeague(event.event_league_name);
      }
    }
  }, [games, setEventLeague, eventLeague]);

  return (
    <>
      <LeftSideBar>
        <div className="swiper-button-prev-unique" />
      </LeftSideBar>
      <StyledSection>
        <Swiper
          grabCursor={true}
          id="main-slider"
          initialSlide={currentGame}
          modules={[Navigation, Mousewheel]}
          mousewheel={true}
          navigation={{
            prevEl: '.swiper-button-prev-unique',
            nextEl: '.swiper-button-next-unique',
          }}
          onSlideChange={(swiper) => {
            slideChanged(swiper.activeIndex);
          }}
          slidesPerView={1}
          tag="section"
          wrapperTag="ul"
        >
          {games?.map((game, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${game.event_id}-${index}`} tag="li">
              <SlideContent>
                <Teams
                  eventType={game?.event_type}
                  location={game.event_location}
                  opponent={game.event_text}
                  team={configuration.one_team_name}
                />
                {game?.event_league_name && game?.event_league_name != '' ? (
                  <LeagueName leagueName={game?.event_league_name} />
                ) : null}
                {(() => {
                  console.log('Game stadium data:', {
                    stadium_name: game.stadium_name,
                    stadium_address: game.stadium_address,
                    location_id: game.event_location_id,
                    stadium_lat: game.stadium_lat,
                    stadium_lng: game.stadium_lng,
                  });
                  return game.stadium_address && game.stadium_name ? (
                    <GameInfoButton
                      city={game.location_city}
                      date={game.event_start}
                      eventLeagueName={game.event_league_name}
                      locationId={game.event_location_id}
                      opponent={game.event_text}
                      region={game.stadium_country}
                      stadium={game.stadium_name}
                      stadiumLocation={{
                        lat: game?.stadium_lat,
                        lng: game?.stadium_lng,
                      }}
                      team={configuration.one_team_name}
                      timeZone={game.stadium_time_zone}
                      zipCode={game.stadium_zip_code}
                    />
                  ) : null;
                })()}
                <GameDate
                  eventState={game.event_state}
                  eventTime={game.event_start}
                />
                <TvChannel channel={game.event_tv_channel} />
                <GameResult
                  date={game.event_start}
                  eventNotesHeadline={game.event_notes_headline}
                  eventState={game.event_state}
                  result={game.event_outcome}
                />
                <CurrentGameButton />
              </SlideContent>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSection>
      <RightSideBar>
        <div className="swiper-button-next-unique" />
      </RightSideBar>
    </>
  );
};

export default memo(DashboardOneTeam);
