import { DEFAULT_TOAST_OPTIONS } from 'constants/names.js';
import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import Modal from 'components/Modal';
import { GameInfoLinkContainer, DirectionButton } from '../style';

export const MapWithDirection = ({
  destinationCoords,
  stadium,
  onClose,
  show,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const getDirection = useCallback(() => {
    setIsButtonDisabled(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      toast.warning(
        'Please make sure that geolocation is supported by this browser.',
        DEFAULT_TOAST_OPTIONS
      );
    }

    function showPosition(position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      if (!lat && !long) {
        toast.warning(
          'Please turn on geolocation on your browser.',
          DEFAULT_TOAST_OPTIONS
        );
      }
      window.open(
        `https://www.google.com/maps/dir/${lat},${long}/${destinationCoords.lat}+${destinationCoords.lng}`
      );
      setIsButtonDisabled(false);
    }
  }, [destinationCoords]);

  return (
    <Modal onClose={onClose} show={show}>
      <GoogleMap location={destinationCoords} />
      <GameInfoLinkContainer>
        <DirectionButton
          disabled={isButtonDisabled}
          onClick={getDirection}
          type="button"
        >
          Directions to {stadium}...
        </DirectionButton>
      </GameInfoLinkContainer>
    </Modal>
  );
};
