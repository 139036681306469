export const color = {
  backgroundDark: '#1d1d1d',
  blue: '#007aff',
  greyText: '#828282',
  red: '#ff3366',
  white: '#ffffff',
  lightGrey: '#bdbdbd',
};

export const font = {
  primary:
    '"Roboto Condensed", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export const fontSize = {
  large: '18px',
  mLarge: '20px',
  medium: '16px',
  regular: '14px',
  small: '12px',
  xLarge: '24px',
};

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1366px',
  desktop: '1920px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
};
