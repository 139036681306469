import { useQuery } from 'react-query';
import { api } from 'api/api';

const parseCoordinate = (value) => {
  if (value === null || value === undefined) return null;
  const parsed = typeof value === 'string' ? parseFloat(value) : value;
  return parsed;
};

export const useStadiumInfo = ({ location_id, stadium, stadium_location }) => {
  const {
    data: stadiumInfo,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['getStadiumInfo', location_id],
    queryFn: async () => {
      if (!location_id) return null;
      const response = await api.games.getStadiumByLocationId(location_id);
      return response; // API response already has the data property
    },
    enabled: !!location_id,
    retry: 1,
  });

  // If we have valid coordinates in props, prioritize those but keep other stadium data
  if (stadium_location?.lat && stadium_location?.lng) {
    const result = {
      stadiumInfo: {
        data: {
          ...stadiumInfo?.data, // Keep any additional stadium data from API
          stadium_name: stadium || stadiumInfo?.data?.stadium_name,
          stadium_lat: parseCoordinate(stadium_location.lat),
          stadium_lng: parseCoordinate(stadium_location.lng),
          stadium_address:
            stadium_location.address || stadiumInfo?.data?.stadium_address,
          location_city: stadiumInfo?.data?.location_city,
        },
      },
      isLoading: false,
      error: null,
    };
    return result;
  }

  // Otherwise return the API data
  const result = {
    stadiumInfo, // API response already has the correct structure
    isLoading,
    error,
  };
  return result;
};
