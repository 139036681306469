import React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';

import Button from 'components/Button';
import { BackgroundImg } from 'components/Header/style.js';
import { StyledTitle } from 'theme/titles.js';
import { ErrorBoundaryWrapper } from './style.js';
import { backgroundImagePath } from './constants';

const analytics = getAnalytics();

/**
 * ErrorBoundary component that catches JavaScript errors anywhere in its child component tree.
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to be rendered and monitored for errors
 * @returns {React.ReactElement} Error boundary wrapper component that displays a fallback UI when errors occur
 * @example
 * ```jsx
 * <ErrorBoundary>
 *   <ComponentThatMayError />
 * </ErrorBoundary>
 * ```
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `Unexpected error: ${errorInfo}. Site url: ${window.location.href}`,
    });
  }

  handleBack() {
    window.history.back();
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <BackgroundImg $imgUrl={backgroundImagePath} />
          <ErrorBoundaryWrapper>
            <StyledTitle>
              Oops, there seems to have been an unexpected error...
            </StyledTitle>
            <Button onClick={() => this.handleBack()}>Go Back</Button>
          </ErrorBoundaryWrapper>
        </>
      );
    }

    return children;
  }
}
