import styled from 'styled-components';
import { color, device, fontSize } from 'theme';

export const StyledButton = styled.button`
  height: 40px;
  min-width: 60px;
  padding: 8px 16px;
  background: ${color.red};
  color: ${color.white};
  border: none;
  border-radius: 5px;
  font-size: ${fontSize.small};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  text-shadow: none;

  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
    color: ${color.white};
  }

  @media ${device.tablet} {
    font-size: ${fontSize.medium};
  }
`;

export const CloseRoundButton = styled.button`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid ${color.red};
  color: ${color.red};
  background: transparent;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid ${color.backgroundDark};
    background-color: ${color.red};

    &:before {
      color: ${color.backgroundDark};
    }
  }

  &::before {
    content: 'X';
  }
`;
