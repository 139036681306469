import {
  championshipLeagues,
  UPCOMING_GAMES_ITEM,
  UPCOMING_GAMES_LABEL,
  ROUTES,
  TODAYS_GAMES_ITEM,
  TODAYS_GAMES_LABEL,
} from 'constants';
import { CHOSEN_TEAM, CHOSEN_LEAGUE } from 'constants/localStorageKey';
import React, { useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createSelectedValue, isEmptyObject } from 'helpers';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { allTeamsResetAction } from 'redux/teams/actionCreators.js';
import {
  allGamesResetAction,
  getAllGamesAction,
} from 'redux/games/actionCreators.js';
import useLocalStorage, {
  useGetSortedLeaguesList,
  useGetCollegeLeagues,
} from 'hooks';
import { api } from 'api/api';
import types from 'redux/types';

import { Selector, AddToFavorite } from 'components';

import { SelectContainer, StyledFlex } from './style';

export const Header = ({ setEventLeague }) => {
  const {
    data: configuration,
    loading: isConfigurationLoading,
    error: isConfigurationError,
  } = useSelector((state) => state.configuration);
  const { data: leagues } = useSelector((state) => state.leagues);
  const { data: teams } = useSelector((state) => state.teams);
  const isUserAuth = !!useSelector((state) => state.user.data.token);
  const userId = useSelector((state) => state.user.data.user_id);
  const favoriteTeams = useSelector((state) => state.favoriteTeams.data);

  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [savedTeam, setSavedTeam] = useLocalStorage(CHOSEN_TEAM, '');
  // eslint-disable-next-line no-unused-vars
  const [savedLeague, setSavedLeague] = useLocalStorage(CHOSEN_LEAGUE, '');

  const params = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ** Log Google Analytics event for changed league ** //
  const analytics = getAnalytics();

  const { leaguesList } = useGetSortedLeaguesList(leagues || []);

  const allTeams = useMemo(() => {
    if (!!teams && !!teams.length && teams[0].team_name) {
      return teams;
    } else {
      return [];
    }
  }, [teams]);

  const handleLeaguesChange = useCallback(
    (selected) => {
      setEventLeague('');
      setSavedTeam('');
      const { value, label } = selected;
      logEvent(analytics, 'select_content', {
        content_type: 'Changed League',
        content_id: label,
      });

      if (
        championshipLeagues.includes(label) ||
        label === UPCOMING_GAMES_LABEL
      ) {
        dispatch(allTeamsResetAction());
        dispatch(allGamesResetAction());
        setSavedLeague('');
        setEventLeague('');
      }

      if (championshipLeagues.includes(label)) {
        setTimeout(() => {
          navigate(
            `/${ROUTES.CHAMPIONSHIPS}?league=${label}&leagueId=${value}`
          );
        }, 500);
      } else if (label === UPCOMING_GAMES_LABEL) {
        setTimeout(() => {
          navigate(`/${ROUTES.UPCOMING_GAMES}`);
        }, 500);
      } else if (label === TODAYS_GAMES_LABEL) {
        dispatch(allGamesResetAction());
        setSearchParams([]);
        setSavedTeam('');
        setSavedLeague('');
      } else {
        setSearchParams(
          (prevValue) => {
            if (params?.team && params?.teamId) {
              prevValue.delete('team');
              prevValue.delete('teamId');
              prevValue.delete('eventId');
            }
            prevValue.set('league', label);
            prevValue.set('leagueId', value);
            return prevValue;
          },
          { replace: true }
        );
      }
    },
    [
      setSavedTeam,
      analytics,
      dispatch,
      setSavedLeague,
      navigate,
      setSearchParams,
      params?.team,
      params?.teamId,
      setEventLeague,
    ]
  );

  const handleTeamChange = useCallback(
    (selected) => {
      dispatch(allGamesResetAction());
      const { value, label } = selected;

      if (label === UPCOMING_GAMES_LABEL) {
        api.games
          .getUpcomingGames({ leagueId: params?.leagueId })
          .then(({ data }) => {
            dispatch({ type: types.GET_GAMES_SUCCESS, payload: data });
            dispatch({ type: types.GET_CURRENT_GAME, payload: 0 });
          })
          .catch(() => {
            dispatch({
              type: types.GET_GAMES_ERROR,
              payload:
                'Something went wrong. It seems there is no info about this team schedule. Please try again later',
            });
          });
      }

      setSearchParams(
        (prevValue) => {
          if (params?.game) {
            prevValue.delete('eventId');
          }
          prevValue.set('team', label);
          prevValue.set('teamId', value);
          return prevValue;
        },
        { replace: true }
      );

      setSavedTeam({ team: label, teamId: value });

      logEvent(analytics, 'select_content', {
        content_type: 'Changed Team',
        content_id: label,
      });
    },
    [
      dispatch,
      setSearchParams,
      setSavedTeam,
      analytics,
      params?.leagueId,
      params?.game,
    ]
  );

  const handleCollegeLeagueChanged = useCallback(
    (selected) => {
      setSavedTeam('');
      const { value, label } = selected;
      logEvent(analytics, 'select_content', {
        content_type: 'Changed College League',
        content_id: label,
      });

      dispatch(allGamesResetAction());

      if (value == UPCOMING_GAMES_ITEM.value) {
        api.games
          .getCollegeUpcomingEvents({
            teamId: configuration?.one_team_id,
            leagueId: Number(value),
          })
          .then(({ data }) => {
            dispatch({ type: types.GET_GAMES_SUCCESS, payload: data });
            dispatch({ type: types.GET_CURRENT_GAME, payload: 0 });
          })
          .catch(() => {
            dispatch({
              type: types.GET_GAMES_ERROR,
              payload:
                'Something went wrong. It seems there is no info about this team schedule. Please try again later',
            });
          });
      } else {
        dispatch(
          getAllGamesAction({
            teamId: configuration?.one_team_id,
            leagueId: Number(value),
          })
        );
      }

      setSearchParams(
        (prevValue) => {
          if (params?.team && params?.teamId) {
            prevValue.delete('team');
            prevValue.delete('teamId');
            prevValue.delete('eventId');
          }
          prevValue.set('collegeLeague', label);
          prevValue.set('collegeLeagueId', value);
          return prevValue;
        },
        { replace: true }
      );
    },
    [
      analytics,
      configuration?.one_team_id,
      dispatch,
      params?.team,
      params?.teamId,
      setSavedTeam,
      setSearchParams,
    ]
  );

  const teamsOptionsList = useMemo(() => {
    let teamsList = [];

    if (params?.league === 'Favorites' && Boolean(favoriteTeams?.length)) {
      teamsList = favoriteTeams?.map((item) => ({
        label: item.favourite_teamLabel,
        value: item.favourite_team_id.toString(),
      }));
      return teamsList;
    } else {
      teamsList = allTeams?.map((item) => ({
        label: item.team_name,
        value: item.team_id.toString(),
      }));

      if (configuration.display_teams_select === '1') {
        const upcomingGamesOption = {
          label: UPCOMING_GAMES_LABEL,
          value: configuration.one_league_id,
        };

        return [upcomingGamesOption, ...teamsList];
      }

      return teamsList;
    }
  }, [
    params?.league,
    favoriteTeams,
    allTeams,
    configuration.display_teams_select,
    configuration.one_league_id,
  ]);

  const isCollegeLeagues =
    configuration.display_one_team === '1' &&
    configuration.sport_level === 'Collegiate';

  const { data: collegeLeagues, isLoading: isCollegeLeaguesLoading } =
    useGetCollegeLeagues(isCollegeLeagues);

  let collegeLeaguesList = [
    UPCOMING_GAMES_ITEM,
    ...((collegeLeagues?.data ? collegeLeagues.data : []) || []),
  ];

  useEffect(() => {
    if (isCollegeLeagues && collegeLeaguesList) {
      const defaultLeague = collegeLeaguesList.find(
        (league) =>
          (league.league_id ? league.league_id : league.value) ===
          UPCOMING_GAMES_ITEM.value
      );

      if (defaultLeague) {
        api.games
          .getCollegeUpcomingEvents({
            teamId: configuration?.one_team_id,
            leagueId: Number(defaultLeague.league_id),
          })
          .then(({ data }) => {
            dispatch({ type: types.GET_GAMES_SUCCESS, payload: data });
            dispatch({ type: types.GET_CURRENT_GAME, payload: 0 });
          })
          .catch(() => {
            dispatch({
              type: types.GET_GAMES_ERROR,
              payload:
                'Something went wrong. It seems there is no info about this team schedule. Please try again later',
            });
          });

        setSearchParams(
          (prevValue) => {
            prevValue.set(
              'collegeLeague',
              defaultLeague.league_name
                ? defaultLeague.league_name
                : defaultLeague.label
            );
            prevValue.set(
              'collegeLeagueId',
              defaultLeague.league_id
                ? defaultLeague.league_id
                : defaultLeague.value
            );
            return prevValue;
          },
          { replace: true }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collegeLeagues?.data, isCollegeLeagues]);

  useEffect(() => {
    if (configuration.display_teams_select === '1' && !params?.team) {
      setSearchParams(
        (prevValue) => {
          if (params?.game) {
            prevValue.delete('eventId');
          }
          prevValue.set('league', configuration?.one_league_name);
          prevValue.set('leagueId', configuration?.one_league_id);
          prevValue.set('team', UPCOMING_GAMES_LABEL);
          prevValue.set('teamId', configuration.one_league_id);
          return prevValue;
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration.display_teams_select, params?.team]);

  if (isConfigurationLoading || Boolean(isConfigurationError)) {
    return null;
  }

  const isNotSelectionMade =
    configuration?.display_leagues_select === '1' && isEmptyObject(params);

  return (
    <>
      {configuration.display_leagues_select === '1' ? (
        <SelectContainer>
          <Selector
            defaultValue={leaguesList[0]}
            onChange={handleLeaguesChange}
            options={leaguesList}
            placeholder="Select League..."
            value={createSelectedValue({
              value: params?.leagueId || TODAYS_GAMES_ITEM.value,
              label: params?.league || TODAYS_GAMES_ITEM.label,
            })}
          />
          {isNotSelectionMade || !teamsOptionsList?.length ? null : (
            <StyledFlex>
              <Selector
                isDisabled={isNotSelectionMade || !teamsOptionsList?.length}
                onChange={handleTeamChange}
                options={teamsOptionsList}
                placeholder={
                  isNotSelectionMade || !teamsOptionsList?.length
                    ? ''
                    : 'Select Team...'
                }
                // placeholder="Select Team..."
                value={createSelectedValue({
                  value: params?.teamId,
                  label: params?.team,
                })}
              />
              {isUserAuth ? <AddToFavorite userId={userId} /> : null}
            </StyledFlex>
          )}
        </SelectContainer>
      ) : null}

      {configuration.display_teams_select === '1' ? (
        <SelectContainer centered={true}>
          <Selector
            onChange={handleTeamChange}
            options={teamsOptionsList}
            placeholder="Select Team..."
            value={createSelectedValue({
              value: params?.teamId || teamsOptionsList[0].value,
              label: params?.team || teamsOptionsList[0].label,
            })}
          />
        </SelectContainer>
      ) : null}

      {isCollegeLeagues && !isCollegeLeaguesLoading ? (
        <SelectContainer centered={true}>
          <Selector
            onChange={handleCollegeLeagueChanged}
            //options={collegeLeaguesList}
            options={collegeLeaguesList?.map((league) => ({
              label:
                league.league_name != null
                  ? league.league_name
                  : league.label != null
                    ? league.label
                    : '',
              value:
                league.league_id != null
                  ? league.league_id.toString()
                  : league.value != null
                    ? league.value.toString()
                    : -1,
            }))}
            placeholder="Select League..."
            value={createSelectedValue({
              value: params?.collegeLeagueId,
              label: params?.collegeLeague,
            })}
          />
        </SelectContainer>
      ) : null}
    </>
  );
};
