import { useQuery } from 'react-query';
import { getGeoCoordinates } from 'api/api';

export const useGetCoordinates = ({
  stadium,
  city,
  isStadiumCoordinates,
  region,
  show,
}) => {
  // Include city in the stadium string for better geocoding results
  const searchTerm = city ? `${stadium}, ${city}` : stadium;

  return useQuery({
    queryKey: ['getCoordinates', show, searchTerm, region],
    queryFn: () => getGeoCoordinates({ stadium: searchTerm, region }),
    enabled: show && !isStadiumCoordinates && !!searchTerm,
    retry: 1,
  });
};
