import {
  championshipLeagues,
  UPCOMING_GAMES_ITEM,
  TODAYS_GAMES_ITEM,
} from 'constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGetSortedLeaguesList = (leagues) => {
  const isUserAuth = !!useSelector((state) => state.user.data.token);
  const userId = useSelector((state) => state.user.data.user_id);

  let leaguesList = useMemo(() => [], []);
  const filteredLeagueList = useMemo(() => [], []);
  const list = useMemo(() => [], []);

  function checkLeague(leagueList, league_id) {
    return leagueList.some(function (leagueObj) {
      let result = leagueObj.value === league_id;

      return result;
    });
  }

  useMemo(() => {
    leagues.forEach((league) => {
      if (!championshipLeagues.includes(league.league_name)) {
        //if (!checkLeague(filteredLeagueList, league.league_id)) {
        filteredLeagueList.push({
          label: league.league_name,
          value: league.league_id.toString(),
        });
        //}
      }
      //if (!checkLeague(list, league.league_id)) {
      list.push({
        label: league.league_name,
        value: league.league_id.toString(),
      });
      //}
    });
  }, [filteredLeagueList, leagues, list]);

  //leaguesList = [UPCOMING_GAMES_ITEM, ...list];

  leaguesList = [TODAYS_GAMES_ITEM, UPCOMING_GAMES_ITEM];

  list.forEach((league) => {
    //leaguesList = [UPCOMING_GAMES_ITEM, league];
    if (!checkLeague(leaguesList, league.value)) {
      leaguesList.push(league);
    }
  });

  //leaguesList = [UPCOMING_GAMES_ITEM, ...list];

  if (isUserAuth && userId) {
    leaguesList.unshift({
      label: 'Favorites',
      value: userId,
    });
  }

  //leaguesList = [TODAYS_GAMES_ITEM, ...leaguesList];

  return {
    leaguesList,
    filteredLeagueList,
  };
};
